import React from 'react';
import { FaDownload, FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Resume() {
  const downloadResume = () => {
    // Replace with the actual path to your resume PDF
    const link = document.createElement('a');
    link.href = '/Robert Daszynski Resume .pdf';
    link.download = 'Robert Daszynski Resume .pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-8">My Resume</h1>
      
      <div className="flex justify-center space-x-4 mb-8">
        <button 
          onClick={downloadResume}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center transition duration-300"
        >
          <FaDownload className="mr-2" />
          Download Resume PDF
        </button>
        <Link 
          to="/" 
          className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded flex items-center transition duration-300"
        >
          <FaHome className="mr-2" />
          Home
        </Link>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <iframe
          src="/Robert Daszynski Resume .pdf"
          title="My Resume"
          className="w-full h-[800px]"
        />
      </div>

      <div className="mt-8 text-center">
        <p className="text-lg">
          This resume provides an overview of my skills, experience, and education. 
          For more detailed information about my projects and achievements, please explore the rest of my portfolio.
        </p>
      </div>
    </div>
  );
}

export default Resume;