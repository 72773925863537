import React from 'react';
import { FaLinkedin, FaEnvelope, FaPhone } from 'react-icons/fa';

function Contact() {
  return (
    <div className="max-w-2xl mx-auto text-center">
      <h1 className="text-4xl font-bold mb-8">Let's Connect!</h1>
      <p className="text-xl mb-12">
        I'm always excited to collaborate on new projects or discuss opportunities. 
        Feel free to reach out through any of the following channels:
      </p>
      
      <div className="space-y-6">
        <a 
          href="https://www.linkedin.com/in/rmdasz" 
          target="_blank" 
          rel="noopener noreferrer"
          className="flex items-center justify-center space-x-3 text-xl hover:text-blue-600 transition-colors duration-300"
        >
          <FaLinkedin className="text-3xl text-blue-500" />
          <span>LinkedIn</span>
        </a>
        
        <a 
          href="mailto:rmdasz@umich.edu"
          className="flex items-center justify-center space-x-3 text-xl hover:text-blue-600 transition-colors duration-300"
        >
          <FaEnvelope className="text-3xl text-blue-500" />
          <span>rmdasz@umich.edu</span>
        </a>
        
        <a 
          href="tel:7736418014"
          className="flex items-center justify-center space-x-3 text-xl hover:text-blue-600 transition-colors duration-300"
        >
          <FaPhone className="text-3xl text-blue-500" />
          <span>(773) 641-8014</span>
        </a>
      </div>
      
      <p className="mt-12 text-lg">
        Whether you have a question about my work
        or just want to say hello, I'd love to hear from you!
      </p>
    </div>
  );
}

export default Contact;
